import { css } from '@emotion/react'
import styled from '@emotion/styled'
import type { SubscriptionNonDimensionalData } from '@orus.eu/backend/src/services/subscription/subscription-service'
import {
  activitiesDimension,
  displayNameByProduct,
  forbiddenMonthlyPaymentExemptionDimension,
  getSelectedProducts,
  hasAcceptedDimension,
  offerInformationDimension,
  productSelectionDimensions,
  type commitmentDimension,
  type forbiddenMonthlyPaymentDimension,
  type PartialDimensionnedState,
  type paymentRecurrenceDimension,
  type quoteDimension,
} from '@orus.eu/dimensions'
import { checkDefinedAndNotNull } from '@orus.eu/error'
import {
  Avatar,
  Button,
  colorTokens,
  EmptyState,
  spacing,
  Text,
  useAsyncCallback,
  useEnqueueToast,
  useLanguage,
} from '@orus.eu/pharaoh'
import { ButtonGroup } from '@orus.eu/pharaoh/src/components/button/button-group'
import { Row, RowContainer } from '@orus.eu/pharaoh/src/components/rows'
import { Dropdown } from '@orus.eu/pharaoh/src/patterns/dropdown'
import { Fragment, memo, useCallback, useMemo, useState } from 'react'
import { trpcReact } from '../../../../../client'
import { useCopyToClipboard } from '../../../../../lib/clipboard-util'
import { assert } from '../../../../../lib/errors'
import { useDownloadAgreedTerms } from '../../../../../lib/hooks/use-download-agreed-terms'
import { usePermissions } from '../../../../../lib/use-permissions'
import { validateEmail } from '../../../../../lib/validation'
import { useBuildUrl } from '../../../../../use-build-url'
import {
  disabledReasonsTooltips,
  getDisabledReasonTooltip,
  noEmailDisabledReasonTooltip,
  noPhoneDisabledReasonTooltip,
  noQuoteDisabledReasonTooltip,
} from '../../../../organisms/share-quote-button'
import { LegacyDataPolicyAcceptanceCheckbox } from '../../../subscription-v2/elements/DataPolicyAcceptanceSubscriptionUiElementBlock'
import { getDisabledPaymentReason } from '../backoffice-pending-subscriptions/pending-subscription-payment-disabled-reasons'
import { PricingDimensionField } from './fields/pricing-dimension-field'
import type { useQuoteEditorState } from './quote-editor-v2-state'
import { getFinalizeSubscriptionButtonTooltip } from './quote-editor-v2-util'
import { QuoteSignSharingRow } from './QuoteSignSharingButton'

export type QuoteEditorV2SidePanelState = PartialDimensionnedState<
  [
    typeof paymentRecurrenceDimension,
    typeof quoteDimension,
    typeof commitmentDimension,
    typeof forbiddenMonthlyPaymentDimension,
    typeof forbiddenMonthlyPaymentExemptionDimension,
    typeof activitiesDimension,
    typeof offerInformationDimension,
    typeof hasAcceptedDimension,
  ]
> &
  PartialDimensionnedState<typeof productSelectionDimensions>

export type QuoteEditorV2SidePanelProps = {
  changes: ReturnType<typeof useQuoteEditorState>['changes']
  stateAfter: ReturnType<typeof useQuoteEditorState>['after']
  state: QuoteEditorV2SidePanelState
  stateBefore: QuoteEditorV2SidePanelState
  nonDimensionalDataAfter: SubscriptionNonDimensionalData
  setChanges: (changes: QuoteEditorV2SidePanelState) => void
  toggleHighlightDimensions: () => void
  isHighlightDimensionsActive: boolean
  isSidePanelOpen: boolean
  toggleIsSidePanelOpen: () => void
  hasQuote: boolean
  currentEmail: string | undefined
  currentPhone: string | undefined
  signatureUrl: string
  subscriptionId: string
}

export const QuoteEditorV2SidePanel = memo(function QuoteEditorV2SidePanel(props: QuoteEditorV2SidePanelProps) {
  const language = useLanguage()
  const {
    state,
    stateBefore,
    stateAfter,
    changes,
    nonDimensionalDataAfter,
    setChanges,
    toggleHighlightDimensions,
    isHighlightDimensionsActive,
    isSidePanelOpen,
    toggleIsSidePanelOpen,
    hasQuote,
    currentEmail,
    currentPhone,
    signatureUrl,
    subscriptionId,
  } = props

  const { type: userType } = usePermissions()

  const partnerSendSubscriptionToSignEmailMutation =
    trpcReact.subscriptions.partnerSendSubscriptionToSignEmail.useMutation()
  const partnerSendProspectSubscriptionEmailMutation =
    trpcReact.subscriptions.partnerSendProspectSubscriptionEmail.useMutation()

  const quote = state.quote

  const activitiesDisplayNames = state.activities?.map((activity) => activity.displayName)
  const products = getSelectedProducts(state)
  const productsDisplayNames = products.map((product) => displayNameByProduct[product])

  const offerDisplayNames = state.offerInformation?.products.map((product) => product.name)
  const hasChanges = Object.keys(props.changes).length > 0

  const disabledPaymentReason = getDisabledPaymentReason({ changes, state: stateBefore, nonDimensionalDataAfter })

  const disabledPaymentReasonMissingFieldsNames = nonDimensionalDataAfter.missingSubscriptionDimensionsNames

  const disabledPaymentReasonMissingFieldsSet = new Set(disabledPaymentReasonMissingFieldsNames)

  const allowedMissingFieldsSet = new Set(['hasAccepted'])
  const canHideEmptySubscriptionState =
    disabledPaymentReasonMissingFieldsSet.size <= allowedMissingFieldsSet.size &&
    [...disabledPaymentReasonMissingFieldsSet].every((x) => allowedMissingFieldsSet.has(x))

  const { enqueueToast } = useEnqueueToast()

  const [sendingEmail, setSendingEmail] = useState(false)
  const [sendingSignQuoteEmail, setSendingSignQuoteEmail] = useState(false)

  const brokerSendProspectEmail = useAsyncCallback(async () => {
    setSendingEmail(true)
    await partnerSendProspectSubscriptionEmailMutation.mutateAsync(subscriptionId)
    enqueueToast('Email envoyé', { variant: 'success' })
    setSendingEmail(false)
  }, [subscriptionId, enqueueToast, partnerSendProspectSubscriptionEmailMutation])

  const brokerSendSubscriptionToSignEmail = useAsyncCallback(async () => {
    setSendingSignQuoteEmail(true)
    await partnerSendSubscriptionToSignEmailMutation.mutateAsync(subscriptionId)
    enqueueToast('Email de signature envoyé', { variant: 'success' })
    setSendingSignQuoteEmail(false)
  }, [subscriptionId, enqueueToast, partnerSendSubscriptionToSignEmailMutation])

  const isCurrentEmailValid = useMemo(
    () => currentEmail && validateEmail(currentEmail, language),
    [currentEmail, language],
  )

  const quoteShareLinkTooltip = useMemo(() => {
    return getQuoteSharingButtonConfiguration({
      hasChanges,
      hasQuote,
      hasEmail: !!currentEmail,
      hasPhone: !!currentPhone,
      nonDimensionalData: props.nonDimensionalDataAfter,
    }).tooltip
  }, [hasChanges, hasQuote, currentEmail, currentPhone, props.nonDimensionalDataAfter])

  const { download: downloadAgreedTerms, downloadInProgress } = useDownloadAgreedTerms({
    subscriptionId,
    changes,
    email: currentEmail,
  })

  const handleDownloadClick = useCallback(() => {
    downloadAgreedTerms().catch((err) => {
      console.error('Unhandled error during download', err)
    })
  }, [downloadAgreedTerms])

  return (
    <SidePanelContainer>
      {isSidePanelOpen ? (
        <>
          <TitleContainer>
            <Text variant="body1Medium">Tarificateur</Text>
            <Button
              onClick={toggleIsSidePanelOpen}
              icon="arrow-right-to-line-regular"
              variant="secondary"
              size="small"
            />
          </TitleContainer>
          {quote ? (
            <>
              <PricingDimensionField
                state={state}
                beforeState={stateBefore}
                setChanges={setChanges}
                nonDimensionalDataAfter={nonDimensionalDataAfter}
              />
              <SidePanelSection>
                <Text variant="body2Medium">Partager le devis</Text>
                <ButtonGroup>
                  {userType === 'partner' ? (
                    <Button
                      variant="secondary"
                      size="small"
                      icon="envelope-regular"
                      avatarPosition="left"
                      disabled={
                        hasChanges ||
                        !props.hasQuote ||
                        !isCurrentEmailValid ||
                        sendingEmail ||
                        props.nonDimensionalDataAfter.canGenerateAgreedTermsDraft.type === 'cannot-generate'
                      }
                      isLoading={sendingEmail}
                      onClick={brokerSendProspectEmail}
                      title={quoteShareLinkTooltip}
                    >
                      Envoyer par mail
                    </Button>
                  ) : undefined}
                  <QuoteSharingButton
                    nonDimensionalData={props.nonDimensionalDataAfter}
                    hasChanges={hasChanges}
                    hasQuote={hasQuote}
                    hasEmail={!!currentEmail}
                    hasPhone={!!currentPhone}
                  />
                  <Button
                    variant="secondary"
                    size="small"
                    icon="arrow-down-to-line-regular"
                    title="Télécharger les conditions particulières"
                    disabled={
                      downloadInProgress ||
                      !props.hasQuote ||
                      !isCurrentEmailValid ||
                      props.nonDimensionalDataAfter.canGenerateAgreedTermsDraft.type === 'cannot-generate'
                    }
                    onClick={handleDownloadClick}
                    ariaLabel="Télécharger les conditions particulières"
                  />
                </ButtonGroup>
              </SidePanelSection>
              {canHideEmptySubscriptionState && stateAfter ? (
                <SidePanelSection>
                  <Text variant="body2Medium">Finaliser la souscription</Text>
                  <LegacyDataPolicyAcceptanceCheckbox
                    hasAccepted={stateAfter?.hasAccepted ?? false}
                    setHasAccepted={(value) => setChanges({ hasAccepted: value })}
                    type="backoffice"
                    operatingZone={checkDefinedAndNotNull(stateAfter.operatingZone)}
                  />
                  <ButtonGroup>
                    {userType === 'partner' ? (
                      <SigningQuoteContainer>
                        <Button
                          variant="primary"
                          size="small"
                          css={css`
                            flex-grow: 1;
                          `}
                          disabled={!!disabledPaymentReason || !!sendingSignQuoteEmail}
                          onClick={brokerSendSubscriptionToSignEmail}
                          isLoading={sendingSignQuoteEmail}
                          title={getFinalizeSubscriptionButtonTooltip(props.nonDimensionalDataAfter, hasChanges)}
                        >
                          Signature client par email
                        </Button>
                        <Dropdown
                          trigger={
                            <Button
                              variant="secondary"
                              size="small"
                              icon="ellipsis-regular"
                              disabled={!!disabledPaymentReason}
                              title={getFinalizeSubscriptionButtonTooltip(props.nonDimensionalDataAfter, hasChanges)}
                            />
                          }
                        >
                          <RowContainer variant="border" size="small">
                            <QuoteSignSharingRow key="1" nonDimensionalData={props.nonDimensionalDataAfter} />
                            <Row
                              subtitle="Finaliser la souscription"
                              leftIcon="check-regular"
                              key="2"
                              href={signatureUrl}
                            />
                          </RowContainer>
                        </Dropdown>
                      </SigningQuoteContainer>
                    ) : (
                      <SigningQuoteContainer>
                        <Button
                          variant="primary"
                          size="small"
                          css={css`
                            flex-grow: 1;
                          `}
                          disabled={!!disabledPaymentReason}
                          href={signatureUrl}
                          title={disabledPaymentReason || ''}
                        >
                          Finaliser la souscription
                        </Button>
                        <Dropdown
                          trigger={
                            <Button
                              variant="secondary"
                              size="small"
                              icon="ellipsis-regular"
                              disabled={!!disabledPaymentReason}
                            />
                          }
                        >
                          <RowContainer variant="border" size="small">
                            <QuoteSignSharingRow key="1" nonDimensionalData={props.nonDimensionalDataAfter} />
                          </RowContainer>
                        </Dropdown>
                      </SigningQuoteContainer>
                    )}
                  </ButtonGroup>
                  {nonDimensionalDataAfter.contract ? (
                    <SuccessPaymentRow>
                      <Avatar
                        variant="contained"
                        color={colorTokens['color-bg-success-primary']}
                        size="40"
                        icon="check-solid"
                      />
                      <Text variant="body2Medium" color={colorTokens['color-bg-success-primary']}>
                        Souscription finalisée
                      </Text>
                    </SuccessPaymentRow>
                  ) : (
                    <></>
                  )}
                </SidePanelSection>
              ) : (
                <EmptyState
                  subtitle="Remplissez la partie Informations pour pouvoir signer et finaliser."
                  icon="pen-light"
                  style="neutral"
                >
                  <EmptyStateButton toggleFunction={toggleHighlightDimensions} active={isHighlightDimensionsActive} />
                </EmptyState>
              )}

              <SummaryBlock
                activities={activitiesDisplayNames}
                products={productsDisplayNames}
                offers={offerDisplayNames}
              />
            </>
          ) : (
            <EmptyState
              icon="calculator-light"
              subtitle="Remplissez la partie Tarificateur pour obtenir un tarif."
              height="320px"
              style="neutral"
            >
              <EmptyStateButton toggleFunction={toggleHighlightDimensions} active={isHighlightDimensionsActive} />
            </EmptyState>
          )}
        </>
      ) : (
        <Button
          onClick={toggleIsSidePanelOpen}
          icon="arrow-left-to-line-regular"
          variant="secondary"
          size="small"
          css={css`
            display: inline-flex;
            width: fit-content;
          `}
        />
      )}
    </SidePanelContainer>
  )
})

const EmptyStateButton = memo(function EmptyStateButton({
  toggleFunction,
  active,
}: {
  toggleFunction: () => void
  active: boolean
}) {
  return (
    <Button onClick={toggleFunction} size="small">
      {active ? 'Masquer les champs requis' : 'Afficher les champs requis'}
    </Button>
  )
})

export const SummaryBlock = memo(function SummaryBlock({
  activities,
  products,
  offers,
}: {
  activities?: string[]
  products?: string[]
  offers?: string[]
}) {
  const sections: { title: string; elements: string[] | undefined }[] = [
    { title: 'Activité', elements: activities },
    { title: 'Produit d’assurance', elements: products },
    { title: 'Offre personnalisée', elements: offers },
  ]

  return (
    <SidePanelSection>
      <Text variant="body2Medium">Récapitulatif</Text>
      <RowContainer variant="border" size="small">
        {sections.map((section) =>
          section.elements ? (
            <Row
              variant="descriptive"
              key={section.title}
              title={section.title}
              titleVariant="body2Medium"
              titleColor={colorTokens['color-text-base-main']}
              subtitleVariant="body2"
              subtitleColor={colorTokens['color-text-base-basic']}
              subtitle={
                <>
                  {section.elements.map((element, index) => (
                    <Fragment key={index}>
                      {element}
                      <br />
                    </Fragment>
                  ))}
                </>
              }
            />
          ) : null,
        )}
      </RowContainer>
    </SidePanelSection>
  )
})

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SidePanelContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${spacing[60]};
  padding: ${spacing[60]};
`

const SidePanelSection = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${spacing[30]};
`

type QuoteSharingButtonProps = {
  nonDimensionalData: SubscriptionNonDimensionalData
  hasChanges: boolean
  hasQuote: boolean
  hasEmail: boolean
  hasPhone: boolean
}

const QuoteSharingButton = memo<QuoteSharingButtonProps>(function QuoteSharingButton(props) {
  const { enabled, token, tooltip } = getQuoteSharingButtonConfiguration(props)
  const { enqueueToast } = useEnqueueToast()
  const { copyToClipboard } = useCopyToClipboard()
  const { buildUrl } = useBuildUrl()

  const copyLink = useCallback(() => {
    assert(token, 'token should be defined')
    copyToClipboard(
      buildUrl({
        to: props.nonDimensionalData.hasEndorsementInProgress ? '/s/e-v2/$token' : '/s/q/$token',
        params: { token },
      }),
    )
    enqueueToast('Lien du devis copié', { variant: 'success' })
  }, [props.nonDimensionalData.hasEndorsementInProgress, token, enqueueToast, buildUrl, copyToClipboard])

  return (
    <Button
      variant="secondary"
      size="small"
      icon="link-regular"
      avatarPosition="left"
      disabled={!enabled}
      onClick={copyLink}
      css={css`
        flex-grow: 1;
      `}
      title={tooltip}
    >
      Copier le lien
    </Button>
  )
})

function getQuoteSharingButtonConfiguration({
  hasChanges,
  hasQuote,
  hasEmail,
  hasPhone,
  nonDimensionalData,
}: QuoteSharingButtonProps): {
  tooltip: string
  enabled: boolean
  token: string | undefined
} {
  if (hasChanges) {
    return {
      enabled: false,
      tooltip: disabledReasonsTooltips['unsaved-changes'],
      token: undefined,
    }
  }

  const { quoteSharingStatus } = nonDimensionalData

  if (quoteSharingStatus.type === 'disabled') {
    return {
      enabled: false,
      tooltip: getDisabledReasonTooltip(quoteSharingStatus),
      token: undefined,
    }
  }

  const canGenerateDraftAgreedTerms = nonDimensionalData.canGenerateAgreedTermsDraft
  if (canGenerateDraftAgreedTerms.type === 'cannot-generate') {
    return {
      enabled: false,
      tooltip: canGenerateDraftAgreedTerms.reason,
      token: undefined,
    }
  }

  if (!hasQuote) {
    return {
      enabled: false,
      tooltip: noQuoteDisabledReasonTooltip,
      token: undefined,
    }
  }
  if (!hasEmail) {
    return {
      enabled: false,
      tooltip: noEmailDisabledReasonTooltip,
      token: undefined,
    }
  }
  if (!hasPhone) {
    return {
      enabled: false,
      tooltip: noPhoneDisabledReasonTooltip,
      token: undefined,
    }
  }

  return {
    enabled: true,
    tooltip: 'Copier le lien à partager avec le client',
    token: quoteSharingStatus.token,
  }
}

const SuccessPaymentRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing[20]};
`

const SigningQuoteContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing['30']};
`
